<section class="sticky-top" *ngIf="isMobileView">
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container py-3 d-block">
      <div class="row d-flex justify-content-between align-items-center">
        <div class="col text-left">
          <span class="navbar-toggler-icon" (click)="toggleNavbar()"></span>
        </div>
        <div class="col text-center">
          <a class="navbar-brand mx-auto" routerLink="/admin/dashboard">
            <img src="assets/images/f1.png" alt="Logo" class="logo" width="120" />
          </a>
        </div>
        <div class="col text-end">
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="collapse navbar-collapse" [ngClass]="{'show': !isNavbarCollapsed}">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <a class="nav-link" routerLink="/admin/dashboard" (click)="toggleNavbar()">Dashboard</a>
              </li>
              <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link" routerLink="/profile" (click)="toggleNavbar()">My Profile</a>
              </li>
            </ul>
            <ul class="navbar-nav ml-auto">
              <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link" (click)="logout()">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div class="overlay" [ngClass]="{'show': !isNavbarCollapsed}" (click)="toggleNavbar()"></div>
</section>

<section class="sticky-top" *ngIf="!isMobileView">
  <div class="container">
    <header class="d-flex flex-nowrap align-items-center justify-content-center justify-content-lg-between gap-3 py-2 border-bottom">
      <div class="col-lg-3 mb-lg-0">
        <a routerLink="/admin/dashboard" class="d-inline-flex link-body-emphasis text-decoration-none">
          <img src="assets/images/f1.png" alt="Logo" class="logo" style="width: 100%; max-width: 150px;" />
        </a>
      </div>
      <div *ngIf="isAuthenticated" class="d-flex justify-content-between align-items-center text-end">
        <div class="nav-item py-1"><div class="vr h-100 mx-4 text-body text-opacity-75"></div></div>
        <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
      </div>
    </header>
  </div>
</section>

<ng-template #userDropdown>
  <div ngbDropdown>
    <button ngbDropdownToggle class="btn btn-link text-primary text-decoration-none py-0 pe-0" aria-label="Open user menu">
      <!-- <img [src]="profile?.image ? profile?.image.url : './assets/images/avatars/2.jpg'" class="rounded-circle" width="40" height="40" alt="{{profile?.name}}" /> -->
      <span class="ms-3">Franchise One</span>
    </button>
    <div ngbDropdownMenu class="w-auto">
      <!-- <a class="dropdown-item" role="button" (click)="openProfile()">
        <fa-icon [icon]="icons.faUser" class="me-2"></fa-icon>
        My Profile
      </a> -->
      <a class="dropdown-item" role="button" (click)="logout()">
        <fa-icon [icon]="icons.faRightFromBracket" class="me-2"></fa-icon>
        Logout
      </a>
    </div>
  </div>
</ng-template>
