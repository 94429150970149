import { Component } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  role: string = '';
  isAuthenticated: boolean = false;

  constructor(
    private auth: AuthService,

  ) {}

  ngOnInit(): void {
    this.auth.isAuthenticated$.subscribe({
      next: (isAuthenticated: boolean) => {
        this.isAuthenticated = isAuthenticated;
        if (isAuthenticated) {
          this.auth.theRole$.subscribe({
            next: (role: string) => {
              this.role = role;
            },
            error: (error: any) => {
              console.error('Error in header component:', error);
            }
          });
        }
      },
      error: (error: any) => {
        console.error('Error in header component:', error);
      }
    });
  }

  logout() {
    this.auth.logout();
  }

}
